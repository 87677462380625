/* src/components/NoteTaking.css */

.note-taking-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .note-taking-container h1 {
    text-align: center;
    font-size: 2em;
    color: #343a40;
    margin-bottom: 20px;
  }
  
  .note-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .note-input {
    padding: 10px;
    font-size: 1.1em;
    border: 1px solid #ced4da;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .note-input:focus {
    border-color: #80bdff;
    outline: none;
    box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
  }
  
  .note-button {
    padding: 10px;
    font-size: 1em;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .note-button:hover {
    background-color: #0056b3;
  }
  
  .notes-list {
    margin-top: 20px;
  }
  
  .notes-list h2 {
    font-size: 1.5em;
    color: #343a40;
    margin-bottom: 10px;
  }
  
  .notes-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .notes-list li {
    background-color: white;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 1.1em;
    color: #495057;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  